<template>
  <div>
    <div class="boxs" v-loading="dataLoading" v-if="is_write != 2">
      <ul>
        <li v-for="item in teacher_data" :key="item.id">
          <span class="s1">{{ item.username }}</span>
          <span class="s2">
            <el-radio-group v-model="item.pf">
              <el-radio :label="3">A.不满意</el-radio>
              <el-radio :label="4">B.较满意</el-radio>
              <el-radio :label="5">C.很满意</el-radio>
            </el-radio-group>
          </span>
        </li>
      </ul>
      <div class="d1">
        <p>对老师的建议(选填)</p>
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
          v-model="textarea"
        >
        </el-input>
        <el-button
          size="small"
          type="primary"
          style="margin-top: 10px"
          @click="yesBtn"
          :loading="btnLoading"
          >确认提交</el-button
        >
      </div>
    </div>
    <el-empty
      description="本月满意度调查已提交"
      v-if="is_write == 2"
    ></el-empty>
  </div>
</template>
<script>
// import * as http from "@/api/order";
import { getTeacher } from "@/api/order";
import { addSatisfactionlevel } from "@/api/order";
export default {
  data() {
    return {
      teacher_data: [],
      radio: 5,
      textarea: "",
      dataLoading: true,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      btnLoading: false,
      is_write: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    yesBtn() {
      let flag = this.teacher_data.every((item) => {
        return item.pf != "";
      });
      if (!flag) {
        this.$message.warning("请给每位老师进行打分");
        return;
      }
      let arr = [];
      this.teacher_data.forEach((item) => {
        arr.push({ username: item.username, pf: item.pf });
      });
      arr.push({ username: "建议反馈", pf: this.textarea });
      let parasm = {
        student_id: this.userInfo.id,
        grade_id: sessionStorage.getItem("gradeID"),
        student_name: this.userInfo.student_name,
        content: arr,
      };
      this.btnLoading = true;
      addSatisfactionlevel({ ...parasm })
        .then((res) => {
          this.$message.success(res.msg);
          this.btnLoading = false;
          this.getList();
        })
        .catch((_) => {
          this.btnLoading = false;
        });
    },
    getList() {
      this.dataLoading = true;
      getTeacher({
        student_id: this.userInfo.id,
        grade_id: sessionStorage.getItem("gradeID"),
      }).then((res) => {
        this.is_write = res.is_write;
        res.teacher_data.forEach((item) => {
          item.pf = "";
        });
        this.teacher_data = res.teacher_data;
        this.dataLoading = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.boxs {
  background-color: #f5f7fa;
  border-radius: 4px;
  padding: 10px 10px 100px 10px;
  box-sizing: border-box;
  ul {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      border: 1px dashed #ccc;
      padding: 4px;
      box-sizing: border-box;
      border-radius: 4px;
      transition: all 0.1s linear;
      &:hover {
        background-color: #fff;
        cursor: pointer;
      }
      .s1 {
        display: inline-block;
        width: 100px;
        color: #409eff;
      }
      .s2 {
        margin-left: 10px;
      }
    }
  }
  .d1 {
    margin-top: 10px;

    p {
      margin-bottom: 10px;
    }
  }
}
</style>
